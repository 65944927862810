@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.layoutSection {
  grid-column: bounds-start / bounds-end;
  composes: layout-grid from '../../styles/_layout.scss';
  position: relative;

  &.roundedTop {
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-top: -16px;
  }
  &.roundedBottom {
    overflow: hidden;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-bottom: -16px;
    z-index: 1;
  }

  &.shadowBottom {
    &:after {
      pointer-events: none;
      content: "";
      display: block;
      height: 128px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    }
  }

  &.shadowTop {
    &:after {
      content: "";
      display: block;
      height: 248px;
      width: 100%;
      position: absolute;
      top: 0;
      pointer-events: none;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  &.padTopS {
    padding-top: 32px;

    @include  breakpoints.breakpoint(l) {
      padding-top: 64px;
    }
  }

  &.padTopM {
    padding-top: 64px;

    @include  breakpoints.breakpoint(l) {
      padding-top: 128px;
    }
  }

  &.padBottomS {
    padding-bottom: 32px;

    @include  breakpoints.breakpoint(l) {
      padding-bottom: 64px;
    }
  }

  &.padBottomM {
    padding-bottom: 64px;

    @include  breakpoints.breakpoint(l) {
      padding-bottom: 128px;
    }
  }

  .layoutInner {
    grid-column: inner-start / inner-end;
  }

  &.inv {
    --color-text: var(--color-white);
    --color-divider: rgba(255,255,255, 0.5);
    --color-divider--light: rgba(255,255,255, 0.3);
  }

  > * {
    isolation: isolate;
    width: 100%;
  }
}

.sectionBg {
  --overlay-from: rgba(255,255,255,0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;


  .backgroundColor {
    position: relative;
    flex: 1 0 auto;
    background-color: var(--bg-color);
    transition: background-color 0.2s ease;
    background-image: linear-gradient(to bottom, var(--gradient-from), var(--gradient-to)) ;
  }

  .backgroundMedia {
    &:after {
      position: absolute;
      height: 25%;
      width: 100%;
      left: 0;
      content: "";
    }

    &.bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      &:after {
        top: 0;
        background-image: linear-gradient(to bottom, var(--overlay-from), rgba(255, 255, 255, 0));
      }
    }

    &.top {
      position: absolute;
      top: 0;
      width: 100%;
      &:after {
        bottom: 0;
        background-image: linear-gradient(to top, var(--overlay-from), rgba(255, 255, 255, 0));
      }
    }
  }
}
