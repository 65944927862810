@use "node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

.layoutGrid {
  --cols-m: 1;
  --cols-d: 2;
  grid-column: main-start / main-end;
  min-height: 0;
  display: grid;
  grid-gap: 32px 24px;
  grid-template-columns: repeat(var(--cols-m),minmax(10px, 1fr));

  @include breakpoints.breakpoint(l) {
    grid-gap: 64px 24px;
    grid-template-columns: repeat(var(--cols-d),minmax(10px, 1fr));
  }
}

.layoutStack {
  --align: flex-start;
  --justify: flex-end;
  --spacing: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: var(--justify);
  align-items: var(--align);
  margin-top: var(--spacing);
}

.article-heading + .article-text-block {
  margin-top: 24px;
}

.layoutComponent {
  --col-start: inner-start;
  --col-end: inner-end;
  --align-x: initial;
  --align-y: initial;
  grid-column: var(--col-start-s, var(--col-start)) / var(--col-end-s, var(--col-end));
  display: flex;
  justify-content: var(--align-x);
  align-items: var(--align-y);

  @include breakpoints.breakpoint(l) {
    grid-column: var(--col-start) / var(--col-end);
  }
}

.layoutItem,
.layoutComponent {
  &.mt_0 {
    margin-top: 0;
  }
  &.mt_8 {
    margin-top: 8px;
  }
  &.mt_16 {
    margin-top: 16px;
  }
  &.mt_24 {
    margin-top: 24px;
  }
  &.mt_32 {
    margin-top: 32px;
  }
  &.mt_40 {
    margin-top: 40px;
  }
  &.mt_48 {
    margin-top: 48px;
  }
  &.mt_64 {
    margin-top: 64px;
  }
  &.mt_128 {
    margin-top: 128px;
  }

  @include breakpoints.breakpoint(l) {
    &.mt_l_0 {
      margin-top: 0;
    }
    &.mt_l_8 {
      margin-top: 8px;
    }
    &.mt_l_16 {
      margin-top: 16px;
    }
    &.mt_l_24 {
      margin-top: 24px;
    }
    &.mt_l_32 {
      margin-top: 32px;
    }
    &.mt_l_40 {
      margin-top: 40px;
    }
    &.mt_l_48 {
      margin-top: 48px;
    }
    &.mt_l_64 {
      margin-top: 64px;
    }
    &.mt_l_128 {
      margin-top: 128px;
    }
  }
}



